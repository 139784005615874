import AbstractModel from '../../abstract/abstract.model'

class Notification extends AbstractModel {
  constructor(data) {
    super(data, [
      'id',
      'type',
      'state',
      'created',
      'modified',
      'title',
      'short_description',
      'description',
      'data',
      'color',
    ])
  }
}

export default Notification
