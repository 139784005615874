<template>
  <div class="notifications">
    <div
      v-for="groupNotification in groupsNotification"
      :key="groupNotification.type"
      class="group-notification"
    >
      <h5 v-if="groupNotification.title">
        {{ groupNotification.title }}
        <span
          v-if="groupNotification.unread > 0"
          class="new badge"
          data-badge-caption=""
          >{{ groupNotification.unread }}</span
        >
      </h5>
      <component
        v-if="groupNotification.component"
        :is="groupNotification.component"
        :notifications="groupNotification.notifications"
      />
    </div>
  </div>
</template>

<script>
import {
  NOTIFICATION_STATE,
  NOTIFICATION_TYPE,
} from '@/modules/notification/notification.data'
import NotificationBienetreList from './types/notification.bienetre.vue'
import NotificationGlobalList from './types/notification.global.vue'
import NotificationInstanceList from './types/notification.instance.vue'
import NotificationTeletransmissionList from './types/notification.teletransmission.vue'

export default {
  name: 'NotificationsList',
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      NOTIFICATION_STATE,
      NOTIFICATION_TYPE,
    }
  },
  computed: {
    groupsNotification() {
      const groups = [
        {
          type: NOTIFICATION_TYPE.BIENETRE,
          title: 'Bien-être',
          notifications: [],
          component: NotificationBienetreList,
        },
        {
          type: NOTIFICATION_TYPE.INSTANCE + '_DIRECTION',
          title: 'Instances direction',
          notifications: [],
          component: NotificationInstanceList,
        },
        {
          type: NOTIFICATION_TYPE.INSTANCE + '_GESTION',
          title: 'Instances gestion',
          notifications: [],
          component: NotificationInstanceList,
        },
        {
          type: NOTIFICATION_TYPE.INSTANCE + '_COMMERCIALE',
          title: 'Instances commerciales',
          notifications: [],
          component: NotificationInstanceList,
        },
        {
          type: NOTIFICATION_TYPE.GLOBAL,
          title: 'Notifications',
          notifications: [],
          component: NotificationGlobalList,
        },
        {
          type: NOTIFICATION_TYPE.TELETRANSMISSION,
          title: 'Télétransmissions',
          notifications: [],
          component: NotificationTeletransmissionList,
        },
      ]

      this.notifications.forEach((notification) => {
        let type = notification.type
        if (type === NOTIFICATION_TYPE.INSTANCE) {
          type = notification.data?.instance ?? {}
          type = Object.keys(type)
            .filter((key) => key.includes('Instance_'))
            .shift()
          type =
            NOTIFICATION_TYPE.INSTANCE + '_' + type.replace('Instance_', '')
        }

        const group = groups.find((group) => group.type === type)

        if (group) {
          group.notifications.push(notification)
        }
      })

      return groups
        .filter((g) => g.notifications.length > 0)
        .map((g) => {
          g.unread = g.notifications.filter(
            (n) => n.state === NOTIFICATION_STATE.UNREAD
          ).length
          return g
        })
    },
  },
  mounted() {
    this.$store.dispatch('production/contrats/refresh')
  },
}
</script>
