<template>
  <div>
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
      :class="{
        unread: NOTIFICATION_STATE.UNREAD === notification.state,
        pending,
      }"
    >
      <div class="notification-text">
        <span class="notification-title">
          {{ notification.title }}
        </span>
        <span class="notification-description">
          {{ notification.description }}
        </span>
      </div>

      <div class="notification-actions">
        <a
          v-if="NOTIFICATION_STATE.UNREAD === notification.state"
          title="Marquer comme lu"
          class="notification-action notification-read blue-text"
          @click="readNotif(notification.id)"
        >
          <i class="material-icons">remove_red_eye</i>
        </a>
        <a
          title="Supprimer"
          class="notification-action notification-delete red-text"
          @click="deleteNotif(notification.id)"
        >
          <i class="material-icons">delete</i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NOTIFICATION_STATE } from '@/modules/notification/notification.data'

export default {
  name: 'NotificationInstanceList',
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      NOTIFICATION_STATE,
      pending: false,
    }
  },
  computed: {
    ...mapGetters('notification', ['notificationById']),
  },
  methods: {
    async readNotif(id) {
      this.pending = true
      await this.$store.dispatch('notification/read', id)
      this.pending = false
    },
    async deleteNotif(id) {
      this.pending = true
      await this.$store.dispatch('notification/delete', id)
      this.pending = false
    },
  },
}
</script>
