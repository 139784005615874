<template>
  <li v-if="groupsNotEmpty.length > 0" class="valign-wrapper">
    <div v-for="group in groupsNotEmpty" :key="group.key">
      <a
        class="waves-effect waves-block waves-light header-button notification-button dropdown-menu"
        data-closeOnClick="false"
        href="javascript:void(0);"
        :data-target="`notifications-dropdown-${group.key}`"
      >
        <i class="material-icons">
          {{ group.icon }}
          <small v-if="group.unread > 0" class="notification-badge">{{
            group.unread
          }}</small>
        </i>
      </a>

      <div
        :id="`notifications-dropdown-${group.key}`"
        class="notifications-dropdown dropdown-content"
      >
        <h5 v-if="group.notifications.length === 0">Aucune notification</h5>
        <notifications-list v-else :notifications="group.notifications" />
      </div>
    </div>
  </li>
</template>

<script>
import {
  NOTIFICATION_STATE,
  NOTIFICATION_TYPE,
} from '@/modules/notification/notification.data'
import { mapGetters } from 'vuex'
import notificationsList from './notifications.list'
import { getDeepValue, setIntervalIfVisible } from '@/plugins/utils'

export default {
  name: 'Notifications',
  components: { notificationsList },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      refreshNotif: null,
      groupConfigs: [
        {
          types: [NOTIFICATION_TYPE.TELETRANSMISSION],
          title: 'Télétransmissions',
          icon: 'wifi_tethering',
          notifKey: 'teletransmission',
        },
        {
          types: [NOTIFICATION_TYPE.INSTANCE],
          title: 'Instances',
          icon: 'business_center',
          notifKey: 'instance',
        },
        {
          types: [NOTIFICATION_TYPE.GLOBAL],
          title: 'Notifications',
          icon: 'notifications_none',
        },
        {
          types: [NOTIFICATION_TYPE.BIENETRE],
          title: 'Bien-être',
          icon: 'tag_faces',
          notifKey: 'bienetre',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('notification', ['notifications', 'notifications_pending']),
    ...mapGetters('user', ['user']),
    groups() {
      const notifs = getDeepValue(this.user, 'dashboard.notifications')

      return this.groupConfigs
        .filter((group) => !group.notifKey || notifs?.[group.notifKey])
        .map((group, index) => {
          const notifications = this.notifications.filter((notification) =>
            group.types.includes(notification.type)
          )

          const unread = notifications.filter(
            (notification) => notification.state === NOTIFICATION_STATE.UNREAD
          ).length

          return {
            key: `${index}-${group.notifKey}`,
            type: group.type,
            title: group.title,
            icon: group.icon,
            notifications,
            unread,
          }
        })
    },
    groupsNotEmpty() {
      return this.groups.filter((group) => group.notifications.length > 0)
    },
  },
  async beforeMount() {
    await this.refreshNotifs()

    this.refreshNotif = setIntervalIfVisible(() => {
      this.refreshNotifs()
    }, 10000)
  },
  beforeDestroy() {
    if (this.refreshNotif) {
      this.refreshNotif()
    }
  },
  methods: {
    async refreshNotifs() {
      if (this.groups.length > 0) {
        await this.$store.dispatch('notification/refresh')
      }
    },
  },
}
</script>
