import { api } from '@/plugins'
import {
  REFRESH_NOTIFICATIONS,
  REFRESH_NOTIFICATIONS_SUCCESS,
  REFRESH_NOTIFICATIONS_FAIL,
} from './mutations'
import { NOTIFICATION_STATE } from '../notification.data'

export default {
  refresh({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_NOTIFICATIONS)

      const query = {}

      const lastModified = state.lastModified
      if (lastModified) {
        query.modifiedAfter = lastModified
        query.withDeleted = true
      }

      api
        .GET('notification/list', query)
        .then(async (datas) => {
          await commit(REFRESH_NOTIFICATIONS_SUCCESS, datas)
          resolve(datas)
        })
        .catch((e) => {
          commit(REFRESH_NOTIFICATIONS_FAIL)
          reject(e)
        })
    })
  },
  async read({ commit, getters, state }, id) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_NOTIFICATIONS)

      const model = getters.notificationById(id)
      if (!model) {
        commit(REFRESH_NOTIFICATIONS_FAIL)
        return reject(new Error('Notification not found'))
      }

      api
        .POST('notification/state', {
          model,
          state: NOTIFICATION_STATE.READ,
        })
        .then(async () => {
          const datas = state.models.map((model) => {
            if (model.id === id) {
              return {
                ...model,
                state: NOTIFICATION_STATE.READ,
              }
            }
            return model
          })
          await commit(REFRESH_NOTIFICATIONS_SUCCESS, datas)
          return resolve(true)
        })
        .catch((e) => {
          commit(REFRESH_NOTIFICATIONS_FAIL)
          return reject(e)
        })
    })
  },
  async delete({ commit, getters, state }, id) {
    return new Promise((resolve, reject) => {
      commit(REFRESH_NOTIFICATIONS)

      const model = getters.notificationById(id)
      if (!model) {
        commit(REFRESH_NOTIFICATIONS_FAIL)
        return reject(new Error('Notification not found'))
      }

      api
        .DELETE('notification/delete', model)
        .then(async () => {
          const datas = state.models.map((model) => {
            if (model.id === id) {
              return {
                ...model,
                state: NOTIFICATION_STATE.DELETED,
              }
            }
            return model
          })
          await commit(REFRESH_NOTIFICATIONS_SUCCESS, datas)
          return resolve(true)
        })
        .catch((e) => {
          commit(REFRESH_NOTIFICATIONS_FAIL)
          return reject(e)
        })
    })
  },
}
