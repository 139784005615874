import Model from '../models/notification.model'
import { compareStr } from '@/plugins/utils'
import { NOTIFICATION_STATE } from '../notification.data'

export default {
  notifications: (state) =>
    state.models.filter((s) => s.state !== NOTIFICATION_STATE.DELETED),
  notificationById: (state) => (id) => {
    const model = state.models
      ? state.models.find((model) => compareStr(model.id)(id))
      : null
    return model ? new Model(model) : null
  },
  notifications_pending: (state) => state.models_pending,
}
