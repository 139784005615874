export default {
  delete: ({ DELETE }, model) =>
    new Promise((resolve, reject) => {
      if (!model.getId()) {
        return reject(new Error(`Erreur paramètre : model ID non valide`))
      }
      return DELETE(`notifications/${model.getId()}`)
        .then((reponse) => {
          const data = reponse.data
          if ([200, 204].includes(reponse.status)) {
            resolve(data)
          } else {
            reject(reponse.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    }),
}
